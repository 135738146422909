<template>
    <ion-page>
        <ion-content ref="extraContent" :fullscreen="true" class="ion-no-padding">
          
          <!-- Custom Content Top -->
          <ion-item lines="full" mode="md" class="wysiwyg-container">
            <ion-label position="stacked" style="overflow: visible;">{{ language.customContent }}</ion-label>
            <MarkdownWYSIWYG v-model="journey.extraTabTopContent" v-if="contentLoaded" :placeholder="language.abideByCopyrightLawsPlaceholder"/>
          </ion-item>
          <AudioPicker v-if="contentLoaded" v-model:data="journey.extraTabTopAudio" v-model:name="journey.extraTabTopAudioFilename" :label="language.contentAudioFile" />

          <!-- Video -->
          <VideoPicker v-if="contentLoaded" :label="language.journeyVideo1Url" v-model:videoUrl="journey.extraVideoUrl" v-model:videoLabel="journey.extraVideoLabel" />


          <!-- Template Text Top Preview -->
          <TemplateItemPlaceholder  :previewText="templateTab.text1 ?? ''"  id="template-text1"/>
          
            <!-- Scripture -->
            <ScripturePicker v-if="contentLoaded" @onRemove="handleRemoveScriptureRef" @onAdd="handleAddScriptureRef" :appInstanceId="journeySet.appInstanceId" :journeyId="journey.id" :tab=3 :scriptureReferences="tabSpecificScriptureReferences" />
            <AudioPicker v-if="contentLoaded" v-model:data="journey.extraTabScriptureAudio" v-model:name="journey.extraTabScriptureAudioFilename" :label="language.scriptureAudioFile" />
          
          <!-- Custom Content Bottom -->
          <ion-item lines="full" mode="md" class="wysiwyg-container">
                <ion-label position="stacked" style="overflow: visible;">{{ language.customContent }}</ion-label>
                <MarkdownWYSIWYG v-model="journey.extraContent" v-if="contentLoaded" :placeholder="language.abideByCopyrightLawsPlaceholder"/>
          </ion-item>
          <AudioPicker v-if="contentLoaded" v-model:data="journey.extraAudio" v-model:name="journey.extraAudioFilename" :label="language.contentAudioFile" />

       <!-- Template Text Bottom Preview -->
       <TemplateItemPlaceholder  :previewText="templateTab.text2 ?? ''"  id="template-text2"/>
        <ResourcePicker v-if="contentLoaded" v-model:heading="journey.extraTabResourceListHeading" :journey-id="journeyId" tab-num="3"/>


        </ion-content>
        <VisualTabs :currentTab="2" :tabs="template.tabs" :activeTabIndexes="activeTabs" v-if="template.tabs"/>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">

import {IonContent, IonItem, IonLabel, IonPage, loadingController} from '@ionic/vue';
import {computed, defineComponent, nextTick, ref} from 'vue';
import FixedFooter from "@/components/FixedFooter.vue";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {execute} from "@/mixins/LoadingMixin";
import {getJourneySet} from "@/services/JourneySetService";
import {getTemplate} from "@/services/TemplateService";
import VisualTabs from "@/components/VisualTabs.vue";
import {JourneyTemplate} from "@/models/JourneyTemplateModel";
import {JourneySet} from '@/models/JourneySetModel';
import {Journey, validateJourney} from "@/models/JourneyModel";
import {getJourney, updateJourney} from "@/services/JourneyService";
import {closeOutline} from 'ionicons/icons';
import router from "@/router";
import {useStore} from "vuex";
import {audioFileChanged} from "@/utils/AudioUtil";
import MarkdownWYSIWYG from "@/components/MarkdownWYSIWYG.vue";
import VideoPicker from '@/components/Video/VideoPicker.vue';
import {ScriptureSelection} from '@/models/ScriptureSelectionModel';
import ScripturePicker from '@/components/ScripturePicker.vue';
import AudioPicker from '@/components/AudioPicker.vue';
import TemplateItemPlaceholder from '@/components/TemplateItemPlaceholder.vue';
import { JourneyTab } from '@/models/JourneyTabModel';
import ResourcePicker from "@/components/ResourcePicker.vue";

export default defineComponent({
    name: 'ExtraContent',
    components: {
        ResourcePicker,
        VisualTabs,
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        FixedFooter,
        MarkdownWYSIWYG,
        ScripturePicker,
        AudioPicker,
        VideoPicker,
        TemplateItemPlaceholder
    },
    props: {
        journeySetId: {
            type: String,
            required: true
        },
        journeyId: {
            type: String,
            required: true
        }
    },
    async ionViewWillLeave() {
        await this.saveProgress();
    },
    async ionViewWillEnter() {
        this.contentLoaded = false;
        await this.store.dispatch('app/setPageTitle', this.language.journeyTab3);
        await execute(async () => {
            this.journeySet = await getJourneySet(this.journeySetId);
            this.journey = await getJourney(this.journeyId);
            this.journey = validateJourney(this.journey);
            this.selectedScriptureReferences = this.journey.scriptureSelections;
            this.contentLoaded = true;
            this.template = await getTemplate(this.journeySet.templateId);
            this.templateTab = this.template.tabs[2];
            if (this.template.enableCustomContent == undefined) this.template.enableCustomContent = false;
        }, this.language.errors.loadingJourney);
        this.extraContent.$el.scrollToTop(300);
    },
    setup(props: any) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const template = ref({} as JourneyTemplate);
        const journeySet = ref({} as JourneySet)
        const journey = ref({} as Journey);
        const templateTab = ref({} as JourneyTab);
        const contentLoaded = ref(false);
        const hasVideo = computed(() => journey.value.extraVideoUrl || journey.value.extraVideoLabel);
        const nextText = computed(() => template.value.enableCustomContent ?  language.value.next : language.value.finishAndSave);
        const selectedScriptureReferences = ref([] as ScriptureSelection[]);
        const tabSpecificScriptureReferences = computed(() => selectedScriptureReferences.value.filter((reference) => reference.tabNum == 3))
        const extraContent = ref();

        const activeTabs = computed(() => {
            const result: number[] = [];
            // if (hasFurtherEditing.value) result.push(2);
            // if (template.value.enableCustomContent) {
            //     result.push(0);
            //     result.push(hasFurtherEditing.value ? 3 : 2);
            // }
            switch (template.value.tabsCount) {
                case 1:
                    result.push(3);
                    break;
                case 2:
                    result.push(0);
                    result.push(3);
                    break;
                case 3:
                    if(template.value.enableCustomContent){
                        result.push(0);
                        result.push(1);
                        result.push(3);
                    }else{
                        result.push(1);
                    }
                    break;
                case 4:
                    if(template.value.enableCustomContent){
                        result.push(0);
                        result.push(1);
                        result.push(2);
                        result.push(3);
                    }else{
                        result.push(1);
                        result.push(2);
                    }
                    break;
                }
                return result
        })

        const save = async () => {
            await execute(async() => {
                await updateJourney(journey.value);
            }, language.value.errors.savingJourney);
        }

        const saveProgress = async () => {
            const loading = await loadingController.create({
                message: language.value.savingProgress,
            });
            await loading.present();
            await save();
            await loading.dismiss();
        }
        
        const saveAndNext = async () => { 
            await saveProgress();

            const nextPage = template.value.enableCustomContent ? {
                name: 'finalContent',
                params: props
            } : {
                name: 'journeySetJourneys',
                params: {journeySetId: props.journeySetId}
            }
            await router.push(nextPage);
        }

        const audioChanged = async (event: any) => {
          const loading = await loadingController.create({
            message: language.value.savingProgress,
          });
          await loading.present();
          await nextTick();
          const {filename, data} = await audioFileChanged(event);
          journey.value.extraAudioFilename = filename;
          journey.value.extraAudio = data;
          await loading.dismiss();
        }

        const handleAddScriptureRef = (newRef: ScriptureSelection) => {
            selectedScriptureReferences.value.push(newRef);
        }

        const handleRemoveScriptureRef = (id: string) => {
            const index = selectedScriptureReferences.value.findIndex((reference) => reference.id == id);
            selectedScriptureReferences.value.splice(index, 1);
        }

        return {
            saveProgress,
            save,
            template,
            journeySet,
            journey,
            extraContent,
            audioChanged,
            closeOutline,
            store,
            language,
            contentLoaded,
            selectedScriptureReferences,
            tabSpecificScriptureReferences,
            hasVideo,
            activeTabs,
            handleAddScriptureRef,
            handleRemoveScriptureRef,
            footerButtons: computed(() => new FooterButtonsBuilder()
                .addClearBackButton()
                .addPrimaryButton(false, async () => await saveAndNext(), nextText.value)
                .create()),
            templateTab
        }
    }
});
</script>

<style scoped>
ion-item button {
    margin-bottom: 20px;
}

.form-section-header {
    padding: 20px 40px;
    color: var(--color-step-600);
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
</style>
<style lang="css" src="../../components/Video/VideoStyling.css"></style>